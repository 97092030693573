exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jadlospis-js": () => import("./../../../src/pages/jadlospis.js" /* webpackChunkName: "component---src-pages-jadlospis-js" */),
  "component---src-pages-kalkulator-insulinoopornosci-js": () => import("./../../../src/pages/kalkulator-insulinoopornosci.js" /* webpackChunkName: "component---src-pages-kalkulator-insulinoopornosci-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-pierwsza-wizyta-js": () => import("./../../../src/pages/pierwsza-wizyta.js" /* webpackChunkName: "component---src-pages-pierwsza-wizyta-js" */),
  "component---src-pages-pomiar-skladu-ciala-js": () => import("./../../../src/pages/pomiar-skladu-ciala.js" /* webpackChunkName: "component---src-pages-pomiar-skladu-ciala-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-wizyta-kontrolna-js": () => import("./../../../src/pages/wizyta-kontrolna.js" /* webpackChunkName: "component---src-pages-wizyta-kontrolna-js" */),
  "component---src-templates-oferta-page-js": () => import("./../../../src/templates/ofertaPage.js" /* webpackChunkName: "component---src-templates-oferta-page-js" */)
}

